@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700&family=Raleway:wght@400;500;800&display=swap');

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

pre.debug {
  color: green;
  background-color: black;
  max-height: 100px;
  overflow: scroll;
}
pre.debug:hover {
  max-height: fit-content;
}

body {
  margin: 0;
  font-family: 'Mulish', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  color: #2c3034 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  font-family: Raleway;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  padding-top: 50px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

a {
  color: #25a5b5;
}
a:hover {
  color: #205879;
}
