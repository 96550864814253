.Toastify__toast {
  border-radius: 5px !important;
}

.Toastify__toast--info {
  background-color: #205879 !important;
}

.Toastify__toast--success {
  background-color: #205879 !important;
}

.Toastify__toast--success-green {
  background-color: #609325 !important;
}

.Toastify__toast--error {
  background-color: #e8303a !important;
}
